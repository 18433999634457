/* camp-detail.css */
.camp-detail-container {
    width: 100%; /* 전체 화면 너비 */
    max-width: 1200px; /* 최대 너비 제한 */
    margin: 0 auto; /* 중앙 정렬 */
    padding: 0 16px; /* 양옆 여백 */
    box-sizing: border-box; /* 패딩 포함 크기 계산 */
}

.camp-detail-title {
    font-size: 1.8rem;
    font-weight: bold;
    /*margin-top: 16px;*/
    /*margin-bottom: 30px;*/
}

.camp-detail-gallery {
    display: grid;
    grid-template-columns: 2fr 1fr; /* 메인 이미지 2칸, 썸네일 1칸 */
    gap: 10px; /* 이미지 간격 */
}

.main-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
}

.thumbnail-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 썸네일 2개씩 배치 */
    gap: 10px;
}

.thumbnail img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
    object-fit: cover;
}

/*캠핑장 이름 위에 작은 주소*/
.camp-detail-small-address {
    font-size: 0.9rem; /* 작은 글씨 크기 */
    color: #868e96; /* Tailwind의 gray-500 */
    margin-bottom: 0.5rem; /* 아래 여백 */
}

/*캠핑장 소개(장문)*/

.intro-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px; /* 제목과 내용 간격 */
}

.intro-icon {
    font-size: 1.5rem; /* 아이콘 크기 */
    margin-right: 8px; /* 아이콘 오른쪽 여백 */
}

.intro-title {
    font-size: 1.25rem; /* 제목 크기 */
    font-weight: bold; /* 굵은 글씨 */
    color: #111827; /* 짙은 색상 */
}

.camp-detail-intro-box {
    background-color: #ffffff; /* 박스 배경색 (흰색) */
    border: 1px solid #000000; /* 테두리 색상 (검정) */
    border-radius: 8px; /* 모서리를 둥글게 처리 */
    padding: 16px; /* 내용물 여백 */
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* 약간의 그림자 */
    line-height: 1.6; /* 텍스트 간격 */
    position: relative; /* 제목을 절대 위치로 배치하기 위한 기준 */
}

.camp-detail-intro-title {
    position: absolute; /* 제목을 박스 위쪽에 배치 */
    top: -12px; /* 박스 경계선 위로 이동 */
    left: 16px; /* 박스 내부 왼쪽 여백과 정렬 */
    background-color: #ffffff; /* 배경 흰색으로 박스와 동일 */
    padding: 0 8px; /* 텍스트 좌우 여백 */
    font-size: 1rem; /* 텍���트 크기 */
    font-weight: bold; /* 텍스트 굵게 */
    color: #111827; /* 텍스트 색상 (짙은 검정색) */
}

.camp-detail-description {
    color: #374151; /* 텍스트 색상 (짙은 회색) */
    font-size: 1rem; /* 텍스트 크기 */
    margin: 0; /* 기본 여백 제거 */
}

/*캠핑장 주소, 전화번호, 홈페이지*/
.camp-detail-info-bar {
    display: flex;
    justify-content: space-between; /* 좌우 정렬 */
    align-items: center; /* 세로 가운데 정렬 */
    padding: 16px; /* 박스 내부 여백 추가 */
    margin: 30px 0; /* 위아래 16px 간격 */
    font-weight: bold; /* 전체 텍스트를 볼드체로 설정 */
    font-size: 10px;
    border: 1px solid #000000; /* 박스 테두리 */
    border-radius: 8px; /* 모서리 둥글게 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* 약간의 그림자 효과 */
    background-color: #ffffff; /* 박스 배경색 흰색 */
    flex-wrap: wrap; /* 화면 크기가 작아질 경우 줄바꿈 */
}

/* 주소 영역 */
.info-bar-section.address {
    flex: 2; /* 주소가 더 많은 공간을 차지 */
    text-align: left;
}
/* 연락처 영역 */
.info-bar-section.contact {
    flex: 1; /* 연락처는 적은 공간 차지 */
    text-align: center;
}

/* 홈페이지 영역 */
.info-bar-section.homepage {
    flex: 1; /* 홈페이지도 적은 공간 차지 */
    text-align: right;
    display: flex;
    justify-content: flex-end; /* 내부 요소를 오른쪽으로 정렬 */
}

/* 공통 스타일 */
.info-bar-section {
    display: flex;
    align-items: center;
    margin: 8px 0;
    font-size: 16px;
}

.info-bar-section:first-child {
    text-align: left; /* 왼쪽 정렬 */
}

.info-bar-section:last-child {
    text-align: right; /* 오른쪽 정렬 */
}


/* 이미지 */
.camp-detail-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 왼쪽과 오른쪽을 동일한 비율로 나눔 */
    gap: 5px; /* 이미지 간의 간격 */
    margin: 20px 0;
    height: 600px; /* 전체 높이를 고정하여 1:1 비율 유지 */
}

.main-image {
    width: 100%;
    height: 600px;
    overflow: hidden; /* 넘치는 부분을 숨김 */
}

.main-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 이미지를 영역에 꽉 채우고 비율 유지 */
    border-radius: 8px;
}

.thumbnail-images-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2x2 형태로 썸네일 배치 */
    grid-template-rows: 1fr 1fr;
    gap: 5px; /* 썸네일 간의 간격 */
    height: 600px; /* 부모 높이에 맞추기 */
    position: relative; /* 내부 요소 위치 설정을 위해 상대 위치 사용 */
}

.thumbnail {
    position: relative; /* 더보기 버튼을 절대 위치로 지정하기 위해 필요 */
    width: 100%;
    height: 100%;
    overflow: hidden; /* 넘치는 부분을 숨김 */
}

.thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 이미지가 썸네일 크기에 맞춰짐 */
    border-radius: 8px;
    cursor: pointer;
}

/* 모달 버튼 스타일 */
.view-more-button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    border: none;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.view-more-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}
/* 이미지 */

/* 홈페이지 링크 스타일 */
.homepage-link {
    color: #000000;                /* 기본 색상은 회색 */
    text-decoration: none;      /* 밑줄 제거 */
    transition: color 0.3s ease; /* 색상 변경을 부드럽게 전환 */
}

/* 마우스를 올렸을 때 노란색으로 변경 */
.homepage-link:hover {
    color: #fcd34d;
}
/* 홈페이지 링크 스타일 */

/* 운영정책, 카카오맵 지도*/

.location-info-container {
    display: flex;
    align-items: center;
    gap: 20px; /* 두 섹션 간의 간격 */
    margin-top: 20px;
    margin-bottom: 20px;
}

.location-info,
.map-container {
    flex: 1; /* 동일한 비율로 공간을 차지 */
}

.location-info-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #111827;
}

.map-container {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 60px; /* 지도 아래에 간격 추가 */
}

#map {
    width: 100%;
    height: 100%; /* 지도의 고정 높이 */
    border-radius: 8px;
}

/* 운영정책, 카카오맵 지도*/

@media (max-width: 770px) {
    .camp-detail-container > div[style*="display: flex"] {
        flex-direction: column !important;
        gap: 30px !important;
    }
    .camp-detail-info-bar {
        flex-direction: column; /* 세로 배치 */
    }
    .info-bar-section {
        flex: unset;
        width: 100%; /* 각 섹션이 한 줄을 차지 */
        text-align: left;
        margin-bottom: 8px; /* 섹션 간 간격 */
    }
    .info-bar-section.homepage {
        text-align: left;
        justify-content: flex-start;
    }
}
