/* 캘린더와 날짜 정보의 공통 부모 컨테이너 */
.datepicker-wrapper {
    width: fit-content;
    margin: 80px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    box-sizing: border-box;
}

/* 캘린더와 날짜 정보를 감싸는 내부 컨테이너 */
.picker-content-wrapper {
    display: flex;
    gap: 40px;
    align-items: stretch;  /* 자식 요소들의 높이를 동일하게 맞춤 */
}

/* 캘린더 컨테이너 */
.react-datepicker-container {
    transform-origin: top;
    scale: 1.025;
    flex: 0 0 auto;
}

/* 캘린더 */
.react-datepicker {
    transform-origin: center;
}

/* 날짜 초기화 버튼 */
.clear-dates-icon {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    z-index: 1;
    cursor: pointer;
}

/* 날짜 정보 영역 */
.date-info {
    width: 320px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
    box-sizing: border-box;
    flex: 0 0 auto;
    box-shadow: none;
}

/* 날짜 정보 타이틀 */
.date-info h2 {
    text-align: center;
    margin: 0;
    padding: 0 20px;
    line-height: 1.5;
    word-break: keep-all;
}

@media (max-width: 1024px) {
    .datepicker-wrapper {
        width: 100%;
        flex-direction: column;
        gap: 30px;
        margin: 40px auto;
        padding: 0 20px;
    }

    .react-datepicker-container {
        transform: scale(1);
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .date-info {
        width: 100%;
        max-width: 400px;
        height: auto;
        min-height: 200px;
    }
}

@media (max-width: 770px) {
    .datepicker-wrapper {
        width: 100%;
        flex-direction: column;  /* 세로 배치 */
        gap: 30px;
        margin: 40px auto;
        padding: 0 20px;
    }

    .picker-content-wrapper {
        flex-direction: column;  /* 내부 컨테이너도 세로 배치 */
        align-items: center;
        gap: 30px;
    }

    .react-datepicker-container {
        transform: scale(1);
        width: 100%;
        display: flex;
        justify-content: center;
    }

    /* 두 번째 달력 표시 및 세로 배치 */
    .react-datepicker__month-container:nth-child(2) {
        display: block;
    }

    .react-datepicker {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .date-info {
        width: 100%;
        max-width: 400px;
        height: auto;
        min-height: 200px;
    }
}

@media (max-width: 480px) {
    .datepicker-wrapper {
        margin: 20px auto;
    }

    .react-datepicker-container {
        transform: scale(0.9);
    }

    .date-info {
        padding: 15px;
    }

    .date-box {
        padding: 8px;
    }
}

/* CampDatePicker 섹션 */
.camp-date-picker-container h2 {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 20px;
    font-weight: initial;
    line-height: 1.5;
    white-space: nowrap;  /* 기본적으로 한 줄로 표시 */
}

@media (max-width: 770px) {
    .camp-date-picker-container h2 {
        white-space: normal;  /* 미디어쿼리에서 줄바꿈 허용 */
    }

    .camp-date-picker-container h2 span {
        display: block;  /* 각 부분을 블록으로 처리하여 줄바꿈 */
        margin: 4px 0;
    }
}

.camp-date-picker-container .camp-date-picker {
    width: 100%; /* 달력 가로 너비 */
    max-width: 600px; /* 최대 너비 제한 */
    margin: 0 auto; /* 가운데 정렬 */
}

/* 날짜 박스 컨테이너 - 기본 상태 (날짜 미선택) */
.date-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    border: 1px dashed #ddd;
    border-radius: 6px;
    gap: 4px;
    transition: all 0.3s ease;
}

/* 날짜가 선택된 상태 */
.date-box.selected {
    border: 1px solid #cbcbcb;
}

/* 라벨과 날짜 텍스트 */
.label {
    font-size: 15px;
    color: #666;
}


.date {
    font-size: 15px;
    /*font-weight: bold;*/
    color: #868686;
}

.date-box.selected .date {
    color: #1e1e1e;
    font-weight: bold;
}


/* 캘린더 날짜 취소 버튼 */
.datepicker-wrapper {
    position: relative;
}

.react-datepicker__day--outside-month {
    visibility: hidden; /* 다음 달 날짜 숨김 */
}

/* 날짜 초기화 버튼 위치 조정 */
.clear-dates-icon {
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    z-index: 1;
    cursor: pointer;
}

/* 호버 효과 추가 */
.clear-dates-icon:hover {
    opacity: 0.7;
}

/* 두 번째 달력 숨기기 */
.react-datepicker__month-container:nth-child(2) {
    display: none;
}